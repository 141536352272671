import React, { useState } from 'react';
import { Link } from "gatsby";
//Elements 
import Para from '../elements/Paragraph'
import Hyperlink from '../elements/HyperLink'
import Title from '../elements/Title'
import ReadMore from '../elements/ReadMore'
import CaseBanner from '../elements/CaseStudyBanner'

import styled, { css } from 'styled-components'
import { TagWrap } from '../static/css/style'

const SlideWrap = styled.div`
    position:relative;
    display:flex;
    height:auto;
    justify-content:flex-start;
    align-items:flex-end;
    width:100%;
    z-index:0;
    height:368px;
    margin-bottom:20px;
    @media screen and (max-width: 479px) {
        justify-content:flex-start;
        align-items:flex-end;
    }
`;
const SlideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    padding: 50px 0% 0 0%;
    margin-bottom:5%:
    @media screen and (max-width: 767px) {
       
    }
    @media screen and (max-width: 479px) {
        padding: 0px 0% 0 0px;
        margin-bottom: 2em;
    }
`;
const CaseTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;    
    color: ${props => props.color || '#fff'};
    padding-bottom:20px;
    margin-bottom: 0;
    width:100%;
    z-index:0;
    @media screen and (min-width: 1920px) {
        width:100%;
    }
`;

const BlueRect = styled.div`
    width: 80%;
    height: 324px;
    background:#4AB9E9;
    transform:translate(0%,0%);
    position:absolute;
    transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    border-radius:20px;
    ${props => props.hovered && css`
        width: 100%;
        height: 368px;
    `} 
    @media screen and (max-width: 479px) {
        
    }
`;
const Frame = styled.div`
    width: 80%;
    height: 324px;
    border: solid 1px #fff;
    position:absolute;
    transform:translate(3%,-3%);
    transition:all 0.4s ease;
    border-radius:20px;
    ${props => props.hovered && css`
        width: 100%;
        height: 368px;
    `} 
    @media screen and (max-width: 479px) {
       
    }
`;

const Img = styled.img`
    -webkit-backface-visibility: hidden; 
    position:  relative;
    width:80%;
    height: 332px;
    margin-left:auto;
    border-radius:20px;
    transition:all 0.4s ease;
    object-fit:cover;
    transform:translate(10px,-0px);
    box-shadow: -30px 3px 35px -22px rgba(0,0,0,0.53);
    ${props => props.hovered && css`
        width: 97%;
        height: 357px;
        transform:translate(0px,12px);
        box-shadow: 0px 18px 36px -16px rgba(0,0,0,0.74);
    `}
    @media screen and (max-width: 479px) {
        transform:translate(0px,-0px);
        width: 84%;
        ${props => props.hovered && css`
            transform:translate(0px,12px);
        `}
    }
`;
const Tag = styled(Link)`
    outline:none;
    font-size:16px;
    font-weight:normal;
    font-family: 'Montserrat', sans-serif;
    z-index:0;
    color:#00BAED;
    font-family:
    margin:0;
    cursor:pointer;
    &:focus{
        outline:none;
    }
    &:hover{
        font-weight:500;
    }
`;

const CaseStudyEl = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const HoverShow = () => {
        setHoverState(hoverState => true);
    };
    const Hoverhide = () => {
        setHoverState(hoverState => false);
    };
    const tileImage = props.data.summary.tileImage;
    return (
        // <CaseStudy> 
        <SlideContainer onMouseEnter={HoverShow} onMouseLeave={Hoverhide}>
            <SlideWrap>
                <BlueRect hovered={hoverState} />
                <Frame hovered={hoverState} />
                {
                    tileImage ?
                        <Img src={tileImage.mediaItemUrl} alt={tileImage.altText} hovered={hoverState} /> :
                        <Img src={props.data.featuredImage.node.mediaItemUrl} hovered={hoverState} />
                }
            </SlideWrap>
            <CaseTitle>{props.data.title}</CaseTitle>
            <TagWrap>
                {props.data.tags.nodes.map(({ node }, index) => (
                    <Tag to={"/tag/" + props.data.tags.nodes[index].slug + "/"} className="text-deco-none mr-4" key={index}>
                        #{props.data.tags.nodes[index].name}
                    </Tag>
                ))}
            </TagWrap>
            <Para size="md" color="#fff" LineHeight="1.53" marginBottom="0" paddingBottom="3%">{props.data.summary.summary}</Para>
            <ReadMore color="#fff" link={props.data.categories.nodes[0].name == "Case Study" ? "/project/" + props.data.slug + "/" : "/journal/" + props.data.slug + "/"} size="sm" align="left" paddingLeft="0">read now</ReadMore>
        </SlideContainer>
        // </CaseStudy>
    );
};
export default CaseStudyEl;