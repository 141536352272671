import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioComponent from "../views/Portfolio/Portfolio"
const Portfolio = () => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Our Portfolio" url="https://www.kwazi.in/portfolio" />
    <PortfolioComponent></PortfolioComponent>
  </Layout>
)

export default Portfolio
