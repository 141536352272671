import React from 'react';
import styled from 'styled-components'
const Link = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    align-self:${props => props.align || 'flex-start'};
    color: #000000;
    width:${props => props.width || 'auto'};
    border-left:3px solid #4ab9e9;
    padding-left:1%;
    margin-bottom:3%;
    cursor:pointer;
`;

const HyperLink = (props) => {
    return (
        <Link width={props.width} align={props.align}>
            {props.children}
        </Link>
    );
};

export default HyperLink;