import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
export const Verse = styled(Link)`
    position:relative;
    margin-bottom:0px;
    z-index:1;
    text-decoration:none;
    color:inherit;
    & .verseBorder{
        width: ${props => props.width || '60%'};
        position:absolute;
        height:2px;
        bottom:0px;
        left:0px;
        background:#00BCEE;
        z-index:-1;
        transition:all 0.3s ease;
        transform:translateY(2px);
    }
    &:hover .verseBorder{
        width:100%;
        height:90%;
        transform:translateY(0px);
    }
`;
export const BannerHeading = styled.h1`
    width: ${props => props.width || '11em'};
    font-family: 'Montserrat', sans-serif;
    font-size: 55px;
    position:relative;
    line-height: 1.22;
    text-align: left;
    color: #fff;
    overflow:hidden;
    font-weight:normal;
    & span{
        font-weight:bold;
    }
    & span.light{
        font-weight:normal;
    }
    ${props => props.hovered && css`
       width:80%;
  `}
    padding-top:3em;
    @media screen and (max-width: 479px) {
        font-size:2em;
    }

`;
export const CaseTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #4ab9e9;
    padding-bottom:2%;
    margin-bottom: 0;
    width:100%;
    z-index:0;
    @media screen and (min-width: 1920px) {
        width:85%;
    }
`;
export const TagWrap = styled.div`
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin-bottom: 0;
    padding-bottom:0.8em;
    z-index:0;
`;


export const Div100 = styled.div`
    padding:4em 0 4em 0;
    display:flex;
    flex-direction:row;
    margin-top:4em;
    @media screen and (min-width: 1920px) {
        padding:8em 0 8em 0;
        margin-top:8em;
    }
    @media screen and (max-width: 767px) {
        padding:4em 0 0 0;
        margin-top:4em;
    }
    @media screen and (max-width: 479px) {
        flex-direction: column-reverse;
    }
`;
export const Div30 = styled.div`
    padding-right:1em;
    width:30%;
    display:flex;
    flex-direction:column;
    @media screen and (max-width: 767px) {
        width:50%;
    }
    @media screen and (max-width: 479px) {
        width:100%;
    }
`;
export const Div70 = styled.div`
    width:70%;
    height:35em;
    @media screen and (max-width: 1920px) {
        height:45em;
    }
    @media screen and (max-width: 991px) {
        height:auto;
    }
    @media screen and (max-width: 767px) {
        width:50%;
    }
    @media screen and (max-width: 479px) {
        width:100%;
    }
`;
export const ProjectImage = styled.img`
    height: 100%;
    width: 100%;
    margin-left: auto;
    object-fit: cover;
    object-position: 50% 50%;
    padding: 0 0 0 5%;
    @media screen and (max-width: 479px) {
        padding: 0 0 0 0%;
    }
`;
export const HugeHeading = styled.h1`
    font-family: 'Montserrat', sans-serif;
    -webkit-text-stroke: 1px #1f1f1f;
    font-size: 421px;
    font-weight: bold;
    line-height: 90%;
    margin-left: -20px;
    text-align: left;
    color: #fff;
    & span{
        color:#4ab9e9;
        -webkit-text-stroke: 1px #4ab9e9;
    }
`;
export const AnimatedHeadingContainer = styled.div`
    position:relative;
    overflow:hidden;
    width:100%;
    padding-top:0vw;
    height:70vh;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    @media screen and (max-width: 991px) {
        height:50vh;
    }
    @media screen and (max-width: 479px) {
        height:80vh;
    }
`;
export const CTA = styled.div`
    position:relative;
`;
export const ContentBlock = styled.div`
    width:90%;
    margin: auto;
    padding-top:9vw;
    padding-bottom:5em;
    @media screen and (max-width: 991px) {
        padding-top:120px;
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        padding-top:75px;
        overflow:hidden;
        padding-bottom:2em;
    }
`;
export const ContactWrap = styled(ContentBlock)`
    width:100%;
    padding-top:40px;
`;