import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import { Link } from "gatsby"
const ReadMoreLink = styled.p`
    font-family: 'Montserrat', sans-serif;
    text-decoration:none;
    color:${props => props.color || '#fff'};
    z-index:1;
    font-size: ${props => props.size == 'sm' ? '1.2em' : '18px'};
    align-items:flex-start;
    -webkit-align-items: flex-start;
  -ms-flex-align: start;
    width:100%;
    text-align:center;
    font-weight:700;
    width: fit-content;
    transition: all 0.35s ease;
    -webkit-transition: all 0.35s ease;
    cursor:pointer;
    line-height: 1.14;
    margin-bottom: 2px;
    @media screen and (max-width: 991px) {
        line-height: 100%;
        margin-bottom: 3px;
    }
    @media screen and (max-width: 479px) {
        font-size: ${props => props.size == 'sm' ? '1.1em' : '16px'};
        margin-bottom: 3px !important;
    }
`;
const ReadMoreHolder = styled.div`
    margin: ${props => props.align == 'left' ? '0 auto 0 0'
        : props.align == 'center' ? '0 0 0 0' : props.align == 'right' ? '0 0 0 0'
            : props.align == '0' ? '0' : 'auto'};
    width:fit-content;
    position:relative;
    &:hover #border{
        height:100%;
        border-radius:100px;
    }
    padding-left:5px;
    padding-right:5px;
    margin-left:${props => props.paddingLeft || 'auto'};
    margin-right:${props => props.marginRight || ''};
    display:${props => props.display || 'flex'};
    margin-top: ${props => props.top + '%' || '0'};
    z-index:10;
`;
const ReadMoreBorder = styled.div`
    height: 1px;
    width: 100%;
    background-color: #00BAED;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    transition:all 0.3s ease;
    -webkit-transition:all 0.3s ease;
    z-index:-1;
`;

const ReadMore = (props) => {
    return (
        props.external == "true" ?
            <a className="text-deco-none" target="_blank" href={props.link}>
                <ReadMoreHolder paddingLeft={props.paddingLeft} marginRight={props.marginRight} align={props.align} top={props.top} zIndex={props.zIndex} display={props.display}>
                    <ReadMoreLink color={props.color} size={props.size}>{props.children}</ReadMoreLink>
                    <ReadMoreBorder id="border"></ReadMoreBorder>
                </ReadMoreHolder>
            </a>

            :
            <Link className="text-deco-none" to={props.link} style={{ "width": "100%" }}>
                <ReadMoreHolder paddingLeft={props.paddingLeft} marginRight={props.marginRight} align={props.align} top={props.top} zIndex={props.zIndex} display={props.display}>
                    <ReadMoreLink color={props.color} size={props.size}>{props.children}</ReadMoreLink>
                    <ReadMoreBorder id="border"></ReadMoreBorder>
                </ReadMoreHolder>
            </Link>

    );
};
export default ReadMore;