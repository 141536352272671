import React, { useState } from 'react';
import styled, { css } from 'styled-components'
const Title = styled.h1`
    width: ${props => props.width || 'auto'} !important;
    font-family: ${props => props.fontFamily || 'Montserrat, sans-serif'};
    font-size: ${props => props.size == 'xxs' ? '20px' : props.size == 'xs' ? '35px' : props.size == 'sm' ? '1.8em' : props.size == 'md' ? '2.85em' : props.size == 'lg' ? '3.2em' : props.size == 'xl' ? '60px' : '3.85em'};
    line-height: ${props => props.lineHeight || '1.25'};
    text-align: ${props => props.align || 'left'};
    color: ${props => props.color || '#000'} !important;
    font-weight:${props => props.weight || 'normal'};
    z-index: ${props => props.zIndex || '0'};
    text-transform: ${props => props.textTransform || 'none'};
    transform: ${props => props.transform || ''};
    transition:all 0.4s ease;
    margin-bottom: ${props => props.bottom || '1.45rem'};
    margin-top: ${props => props.top || ''};
    opacity: ${props => props.opacity || props.opacityTransition === true ? '100' : props.opacityTransition === false ? '0' : '100'};
    @media screen and (max-width: 991px) {
      font-size: ${props => props.size == 'xxs' ? '16px' : props.size == 'xs' ? '22px' : props.size == 'sm' ? '1.7em' : props.size == 'md' ? '2.05em' : props.size == 'lg' ? '2.5em' : props.size == 'xl' ? '50px' : '2.45em'} !important;
    }
    @media screen and (max-width: 767px) {
      font-size: ${props => props.size == 'xxs' ? '14px' : props.size == 'xs' ? '26px' : props.size == 'sm' ? '1.4em' : props.size == 'md' ? '1.6em' : props.size == 'lg' ? '1.8em' : props.size == 'xl' ? '45px' : '2em'} !important;
      width: ${props => props.width == 'max-content' ? 'auto !important' : props.width || 'auto'};
    }
    @media screen and (max-width: 479px) {
      font-size: ${props => props.size == 'xxs' ? '14px' : props.size == 'xs' ? '20px' : props.size == 'sm' ? '1.4em' : props.size == 'md' ? '1.6em' : props.size == 'lg' ? '1.4em' : props.size == 'xl' ? '30px' : '2em'} !important;
    }
    & span{
        color:#4ab9e9;
        font-style:italic;
    }
    ${props => props.hoverState && css`
        color:#000;
  `}
    ${props => props.transition && css`
        transform: translate(-150px, 30px);
    `}
    ${props => !props.transition && css`
        transform: translate(0px, 0px);
    `}
    ${props => props.opacityTransition && css`
        transform:scale(1) !important;
    `}
   

`;
const TitleElement = (props) => {
  return (
    <Title
      width={props.width}
      color={props.color}
      size={props.size}
      lineHeight={props.lineHeight}
      weight={props.weight}
      zIndex={props.zIndex}
      key={props.key}
      bottom={props.bottom}
      top={props.top}
      hoverState={props.hoverState}
      transition={props.transition}
      opacityTransition={props.opacityTransition}
      opacity={props.opacity}
      align={props.align}
      transform={props.transform}
      textTransform={props.textTransform}
      fontFamily={props.fontFamily}
    >
      {props.children}
    </Title>
  );
};
export default TitleElement;