import React,{ useState,useEffect } from 'react';
import styled,{ css } from 'styled-components'
import slimg from'../images/img25.jpg'
const BlueRect = styled.div`
    width: 320px;
    height: 320px;
    background:#4ab9e9;
    transform:translate(40px,-30px);
    -webkit-transform:translate(40px,-30px);
      -moz-transform:translate(40px,-30px);
      -ms-transform:translate(40px,-30px);
    transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    ${props => props.hovered && css`        
        transform:translate(0px,-30px);
        -webkit-transform:translate(0px,-30px);
      -moz-transform:translate(0px,-30px);
      -ms-transform:translate(0px,-30px);
        width: 280px;
        height: 360px;
  `}
`;
const Frame = styled.div`
    width: 320px;
    height: 320px;
    border: solid 1px #000;
    position:absolute;
    transition:all 0.4s ease;
    tran-webkit-transitionsition:all 0.4s ease;
    left:0%;
    right:0%;
    bottom:0;
    top:0;
    z-index:10;
    transform:translateX(50px);
    -webkit-transform:translateX(50px);
      -moz-transform:translateX(50px);
      -ms-transform:translateX(50px);
    border-collapse: separate;border-spacing: 0px;
    ${props => props.hovered && css`
        transform:translateX(10px);
        -webkit-transform:translateX(10px);
      -moz-transform:translateX(10px);
      -ms-transform:translateX(10px);
        width: 280px;
        height: 360px;
    `}
`;
const SlideWrap = styled.div`
    position:relative;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    height:360px;
    justify-content:flex-start;
    -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
    padding-top:40px;
    margin-bottom:5%;
`;
const Img = styled.img`
    position:  absolute;
    width: 320px;
    height: 320px;
    transform-style: preserve-3d;
    -webkit-transform-style:preserve-3d;
      -moz-transform-style:preserve-3d;
      -ms-transform-style:preserve-3d;
    transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    transform:translate(0px,40px);
    -webkit-transform:translate(0px,40px);
      -moz-transform:translate(0px,40px);
      -ms-transform:translate(0px,40px);
    left:0%;
    right:0%;
    bottom:0;
    top:0;
    z-index:20;
    object-fit:cover;
    ${props => props.hovered && css`
        transform:translate(10px,10px);
        -webkit-transform:translate(10px,10px);
      -moz-transform:translate(10px,10px);
      -ms-transform:translate(10px,10px);
        width: 270px;
        height: 350px;
  `}
`;

const CaseStudy = () => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverShow = () =>{
        setHoverState(hoverState => true);        
    };
    useEffect(() => {
        setHoverState(hoverState => false);  
      }, []);
    const toggleHoverHide = () =>{
        setHoverState(hoverState => false);        
    };
    return (        
        <SlideWrap onMouseEnter={toggleHoverShow} onMouseLeave={toggleHoverHide}>
            <Frame hovered={hoverState}/>
            <BlueRect hovered={hoverState}/>            
            <Img src={slimg} hovered={hoverState} ></Img>      
        </SlideWrap>
    );
};

export default CaseStudy;